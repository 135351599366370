import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import PrivateRoute from "../../PrivateRoute";

import KitchenSinkApp from "../../defaultPages/KitchenSinkApp";
import StartComponent from "../../mycomponents/start";
import TestComponent from "../../mycomponents/test";

import * as actions from "../../store/action";

import {
  CometChatUI,
  CometChatConversationList,
  CometChatConversationListWithMessages,
  CometChatUserList,
  CometChatUserListWithMessages,
  CometChatGroupList,
  CometChatGroupListWithMessages,
  CometChatMessages,
} from "../../cometchat-ui";

import { wrapperStyle } from "./style";

const history = createBrowserHistory();

class App extends React.Component {
  state = {
    isLoggedin: false,
  };

  componentDidMount() {
    this.props.getLoggedinUser();
  }

  render() {
    const { palette } = createTheme();
    const { augmentColor } = palette;
    const createColor = (mainColor) =>
      augmentColor({ color: { main: mainColor } });

    const THEME = createTheme({
      typography: {
        fontFamily: "Poppins",
      },
      palette: {
        skeezerLightGreen: createColor("#31dc89"),
        skeezerLightGreen2: createColor("#3dd2af"),
        red: createColor("#EE4B2B"),
      },
    });

    return (
      <ThemeProvider theme={THEME}>
        {/* <div css={wrapperStyle()}> */}
          <Router history={history}>
            <Switch>
              {/* <PrivateRoute path="/embedded-app" component={CometChatUI} />
              <PrivateRoute exact path="/" component={CometChatUI} /> */}
              <Route exact path="/" component={StartComponent} />
              <PrivateRoute path="/app" component={CometChatUI} />
              <Route path="/login" component={KitchenSinkApp} />
              {/* <Route path="/" component={TestComponent} /> */}
            </Switch>
          </Router>
        {/* </div> */}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoggedinUser: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
