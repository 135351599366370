import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
// import { styled, makeStyles } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import FormHelperText from "@mui/material/FormHelperText";
import * as actions from "../store/action";
import { COMETCHAT_CONSTANTS } from "../consts";
import { CometChat } from "@cometchat-pro/chat";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: "200px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const random_user_name = `user${Math.floor(Math.random() * 999999) + 111111}`;

function StartInterface(props) {
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);

  const [country, setCountry] = useState({ label: "" });
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [languages, setLanguages] = useState([]);
  const [topics, setTopics] = useState([]);
  const [uid, setUid] = useState("cleve");
  const [nickname, setNickname] = useState(random_user_name);
  const [error, setError] = useState(false);
  const [error_text, setErrorText] = useState("");

  let data = {
    country,
    gender,
    age,
    languages,
    topics,
    nickname,
    uid,
  };
  const { isLoggedIn, onLogin } = props;
  useEffect(() => {
    if (isLoggedIn) {
      routeChange("/app");
    }
  }, [props]);


  const skeezerStart = () => {
    setPage(6);
    console.log(data);

    var user = new CometChat.User(uid);
    user.setName(nickname.charAt(0).toUpperCase() + nickname.slice(1));
    user.setMetadata(data);
    CometChat.updateUser(user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
      (user) => {
        console.log("user updated", user);
      },
      (error) => {
        console.log("error", error);
      }
    );

    setTimeout(() => {
      onLogin(uid, COMETCHAT_CONSTANTS.AUTH_KEY);
    }, 7000);

    // CometChat.createUser(user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
    //   (user) => {
    //     console.log("user created", user);
    //     // console.log(user.uid);
    //     setTimeout(() => {
    //       onLogin(user.uid, COMETCHAT_CONSTANTS.AUTH_KEY);
    //     }, 5000);
    //   },
    //   (error) => {
    //     console.log("error", error);
    //     console.log("Unable to create user");
    //   }
    // );
  };

  const nextPage = () => {
    const proceed = () => {
      setPage(page + 1);
      console.log(data);
      progress < 100
        ? setProgress(progress + page_progress_min)
        : setProgress(page_progress_min);

      return true;
    };

    const containsSpecialChars = (str) => {
      const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
      console.log(specialChars.test(str));
      return specialChars.test(str);
    };

    const hasWhiteSpace = (str) => {
      return /\s/g.test(str);
    };

    switch (page) {
      case 0:
        if ((country && !country.code) || !country) {
          setError(true);
          setErrorText("this field is required");
        } else {
          setError(false);
          setErrorText("");
          proceed();
        }
        break;

      case 1:
        if (!gender) {
          setError(true);
          setErrorText("this field is required");
        } else {
          setError(false);
          setErrorText("");
          proceed();
        }
        break;

      case 2:
        if (!age) {
          setError(true);
          setErrorText("this field is required");
        } else {
          setError(false);
          setErrorText("");
          proceed();
        }
        break;

      case 3:
        if (!languages.length) {
          setError(true);
          setErrorText("this field is required");
        } else if (languages.length > 3) {
          setError(true);
          setErrorText("you can add a maximum of 3 languages");
        } else {
          setError(false);
          setErrorText("");
          proceed();
        }
        break;

      case 4:
        if (!topics.length) {
          setError(true);
          setErrorText("select some topics to proceed");
        } else {
          setError(false);
          setErrorText("");
          proceed();
        }
        break;

      case 5:
        // api cal to check if username is taken
        if (!nickname) {
          setError(true);
          setErrorText("this field is required");
        } else if (nickname.length < 10) {
          setError(true);
          setErrorText("your nickname is too short");
        } else if (nickname.length > 30) {
          setError(true);
          setErrorText("your nickname is too long");
        } else if (containsSpecialChars(nickname) || hasWhiteSpace(nickname)) {
          setError(true);
          setErrorText(
            "your nickname cannot contain spaces or any special characters"
          );
        } else {
          setError(false);
          setErrorText("");
          skeezerStart();
        }
        break;

      default:
        return null;
      // break;
    }
  };

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  let page_progress_min = 100 / 5;

  return (
    <Container maxWidth="lg" style={{ marginTop: "5vh" }}>
      <Box
        sx={{
          backgroundColor: "#fafafa",

          minHeight: "70vh",
          margin: "20px",
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <LinearProgress
          sx={{ height: 20 }}
          variant="determinate"
          value={progress}
          style={{
            colorPrimary: "#31dc89",
          }}
          color="skeezerLightGreen"
        />
        {page === 0 && (
          <Box>
            <Typography variant="h5" margin={10} sx={{}}>
              Select your country
            </Typography>
            <Box
              sx={{ margin: "20px", justifyContent: "center", display: "flex" }}
            >
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 300 }}
                options={countries}
                autoHighlight
                defaultValue={country}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error_text}
                    color="skeezerLightGreen"
                    label="choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, value) => setCountry(value)}
              />
            </Box>
          </Box>
        )}

        {page === 1 && (
          <Box>
            <Typography variant="h5" margin={10} sx={{}}>
              Select your gender
            </Typography>
            <Box
              sx={{ margin: "20px", justifyContent: "center", display: "flex" }}
            >
              <FormControl sx={{ m: 1, width: 300 }} error={error}>
                <InputLabel color="skeezerLightGreen">gender</InputLabel>
                <Select
                  value={gender}
                  onChange={(event, value) => {
                    setGender(event.target.value);
                  }}
                  // displayEmpty
                  // inputProps={{ "aria-label": "Without label" }}
                  label="Gender"
                  color="skeezerLightGreen"
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <em>Select Gender</em>
                  </MenuItem>
                  {genders.map((gender) => {
                    return (
                      <MenuItem key={gender.id} value={gender}>
                        {gender.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{error_text}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
        )}

        {page === 2 && (
          <Box>
            <Typography variant="h5" margin={10} sx={{}}>
              Select your age range
            </Typography>
            <Box
              sx={{ margin: "20px", justifyContent: "center", display: "flex" }}
            >
              <FormControl sx={{ m: 1, width: 300 }} error={error}>
                <InputLabel color="skeezerLightGreen">age</InputLabel>
                <Select
                  value={age}
                  onChange={(event, value) => {
                    console.log(event.target.value);
                    setAge(event.target.value);
                  }}
                  // displayEmpty
                  // inputProps={{ "aria-label": "Without label" }}
                  label="age"
                  color="skeezerLightGreen"
                  MenuProps={MenuProps}
                >
                  <MenuItem value="">
                    <em>select age</em>
                  </MenuItem>
                  {ages.map((age) => {
                    return (
                      <MenuItem key={age.id} value={age}>
                        {age.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{error_text}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
        )}

        {page === 3 && (
          <Box>
            <Typography variant="h5" margin={10} sx={{}}>
              Select your preferred languages
            </Typography>
            <Box
              sx={{ margin: "20px", justifyContent: "center", display: "flex" }}
            >
              <Autocomplete
                sx={{ width: 300 }}
                limitTags={2}
                multiple
                options={languages_list}
                getOptionLabel={(option) => option.name}
                defaultValue={languages}
                filterSelectedOptions
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="languages"
                    placeholder={"search"}
                    color="skeezerLightGreen"
                    error={error}
                    helperText={error_text}
                  />
                )}
                onChange={(event, value) => {
                  setLanguages(value);
                }}
              />
            </Box>
          </Box>
        )}

        {page === 4 && (
          <Box>
            <Typography variant="h5" margin={10} sx={{}}>
              Select your topics
            </Typography>
            <Box
              sx={{ margin: "20px", justifyContent: "center", display: "flex" }}
            >
              <FormControl sx={{ m: 1, width: 300 }} error={error}>
                <InputLabel color="skeezerLightGreen">topics</InputLabel>
                <Select
                  multiple
                  // minRows={1}
                  // maxRows={2}
                  value={topics}
                  onChange={(event, value) => {
                    setTopics(
                      typeof event.target.value === "string"
                        ? event.target.value.split(",")
                        : event.target.value
                    );
                  }}
                  input={<OutlinedInput label="topics" />}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                        maxHeight: 75,
                        overflowY: "scroll",
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  color="skeezerLightGreen"
                  label="topics"
                >
                  {topics_list.map((topic) => (
                    <MenuItem key={topic.id} value={topic}>
                      <Checkbox checked={topics.indexOf(topic) > -1} />
                      <ListItemText primary={topic.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{error_text}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
        )}

        {page === 5 && (
          <Box>
            <Typography variant="h5" margin={10} sx={{}}>
              Enter a nickname you'd like to use
            </Typography>
            <Box
              sx={{ margin: "20px", justifyContent: "center", display: "flex" }}
            >
              <TextField
                value={nickname}
                onChange={(event) => {
                  setNickname(event.target.value);
                }}
                color="skeezerLightGreen"
                label="nickname"
                variant="outlined"
                error={error}
                helperText={error_text}
              />
            </Box>
          </Box>
        )}

        {page === 6 && (
          <Box>
            {/* <Typography variant="h5" margin={10} sx={{}}>
              Select your topics
            </Typography> */}
            <Box
              sx={{ margin: "75px", justifyContent: "center", display: "flex" }}
            >
              <div>
                <svg
                  role="img"
                  aria-label="Skeezer Loading"
                  class="smiley"
                  viewBox="0 0 128 128"
                  width="128px"
                  height="128px"
                >
                  <defs>
                    <clipPath id="smiley-eyes">
                      <circle
                        class="smiley__eye1"
                        cx="64"
                        cy="64"
                        r="8"
                        transform="rotate(-40,64,64) translate(0,-56)"
                      />
                      <circle
                        class="smiley__eye2"
                        cx="64"
                        cy="64"
                        r="8"
                        transform="rotate(40,64,64) translate(0,-56)"
                      />
                    </clipPath>
                    <linearGradient
                      id="smiley-grad"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stop-color="#000" />
                      <stop offset="100%" stop-color="#fff" />
                    </linearGradient>
                    <mask id="smiley-mask">
                      <rect
                        x="0"
                        y="0"
                        width="128"
                        height="128"
                        fill="url(#smiley-grad)"
                      />
                    </mask>
                  </defs>
                  <g
                    stroke-linecap="round"
                    stroke-width="12"
                    stroke-dasharray="175.93 351.86"
                  >
                    <g>
                      <rect
                        fill="#4096f9"
                        width="128"
                        height="64"
                        clip-path="url(#smiley-eyes)"
                      />
                      <g fill="none" stroke="#31dc89">
                        <circle
                          class="smiley__mouth1"
                          cx="64"
                          cy="64"
                          r="56"
                          transform="rotate(180,64,64)"
                        />
                        <circle
                          class="smiley__mouth2"
                          cx="64"
                          cy="64"
                          r="56"
                          transform="rotate(0,64,64)"
                        />
                      </g>
                    </g>
                    <g mask="url(#smiley-mask)">
                      <rect
                        fill="#31dc89"
                        width="128"
                        height="64"
                        clip-path="url(#smiley-eyes)"
                      />
                      <g fill="none" stroke="hsl(223,90%,50%)">
                        <circle
                          class="smiley__mouth1"
                          cx="64"
                          cy="64"
                          r="56"
                          transform="rotate(180,64,64)"
                        />
                        <circle
                          class="smiley__mouth2"
                          cx="64"
                          cy="64"
                          r="56"
                          transform="rotate(0,64,64)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>

                <Typography variant="h6" marginTop={10} sx={{ color: "grey" }}>
                  please wait as we load your customized experience
                </Typography>
              </div>
            </Box>
          </Box>
        )}

        {progress === 100 ? (
          page !== 6 && (
            <Button
              variant="contained"
              size="large"
              sx={{
                float: "right",
                // marginRight: "20px",
                // marginTop: "21vh",
                position: "absolute",
                bottom: "20px",
                right: "20px",

                alignSelf: "flex-end",
              }}
              color="skeezerLightGreen"
              onClick={nextPage}
            >
              <span style={{ color: "#fff" }}>Start</span>
            </Button>
          )
        ) : (
          <Button
            variant="contained"
            size="large"
            sx={{
              float: "right",
              // marginRight: "20px",
              // marginTop: "21vh",
              position: "absolute",
              bottom: "20px",
              right: "20px",

              alignSelf: "flex-end",
            }}
            color="skeezerLightGreen"
            onClick={nextPage}
          >
            <span style={{ color: "#fff" }}>Next</span>
          </Button>
        )}

        {progress > 0 && page !== 6 ? (
          <Button
            variant="contained"
            size="large"
            sx={{
              float: "left",
              // marginRight: "20px",
              // marginTop: "21vh",
              position: "absolute",
              bottom: "20px",
              left: "20px",

              alignSelf: "flex-start",
            }}
            color="skeezerLightGreen"
            onClick={() => {
              setPage(page - 1);
              console.log(data);
              setProgress(progress - page_progress_min);
            }}
          >
            <span style={{ color: "#fff" }}>Prev</span>
          </Button>
        ) : null}
      </Box>
    </Container>
  );
}

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
//data

const phrases = [
  "please wait as we load your customized experience",
  // 'please wait',
  "matching you with a listener that fits!",
  "skeezer chats are moderated in real time",
  // 'don\'t be shy ;)',
  "what do you wanna talk about?",
  "You are entering a safe zone, free of judgement and bias...",
];

let topics_list = [
  {
    id: 0,
    name: "general talk",
  },
  {
    id: 1,
    name: "depression",
  },
  {
    id: 2,
    name: "divorce",
  },
  {
    id: 3,
    name: "realtionship issues",
  },
  {
    id: 4,
    name: "work & career",
  },
  {
    id: 5,
    name: "self-esteem",
  },
  {
    id: 6,
    name: "anxiety",
  },
  {
    id: 7,
    name: "stress",
  },
  {
    id: 8,
    name: "grief/loss",
  },
  {
    id: 9,
    name: "LGBTQ+ issues",
  },
  {
    id: 10,
    name: "chronic illness",
  },
  {
    id: 11,
    name: "substance abuse",
  },
  {
    id: 12,
    name: "personal growth",
  },
  {
    id: 13,
    name: "disability",
  },
  {
    id: 14,
    name: "family",
  },
  {
    id: 15,
    name: "parenting",
  },
  {
    id: 16,
    name: "trauma/abuse",
  },
  {
    id: 17,
    name: "loneliness",
  },
  {
    id: 18,
    name: "gender/identity exploration",
  },
  {
    id: 19,
    name: "FGM",
  },
  {
    id: 20,
    name: "parental abuse",
  },
];

// topics_list = shuffle(topics_list);

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const genders = [
  {
    id: 1,
    name: "male",
  },
  {
    id: 2,
    name: "female",
  },
  {
    id: 3,
    name: "non-binary",
  },
  {
    id: 4,
    name: "i prefer not to say",
  },
];

const ages = [
  {
    id: 0,
    name: "i prefer not to say",
  },
  {
    id: 1,
    name: "18-22",
  },
  {
    id: 2,
    name: "23-25",
  },
  {
    id: 3,
    name: "25-30",
  },
  {
    id: 4,
    name: "30-35",
  },
  {
    id: 5,
    name: "35-45",
  },
  {
    id: 6,
    name: "46-55",
  },
  {
    id: 7,
    name: "55+",
  },
];

let ll = {
  af: "Afrikaans",
  sq: "Albanian - shqip",
  am: "Amharic - አማርኛ",
  ar: "Arabic - العربية",
  an: "Aragonese - aragonés",
  hy: "Armenian - հայերեն",
  ast: "Asturian - asturianu",
  az: "Azerbaijani - azərbaycan dili",
  eu: "Basque - euskara",
  be: "Belarusian - беларуская",
  bn: "Bengali - বাংলা",
  bs: "Bosnian - bosanski",
  br: "Breton - brezhoneg",
  bg: "Bulgarian - български",
  ca: "Catalan - català",
  ckb: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
  zh: "Chinese - 中文",
  "zh-HK": "Chinese (Hong Kong) - 中文（香港）",
  "zh-CN": "Chinese (Simplified) - 中文（简体）",
  "zh-TW": "Chinese (Traditional) - 中文（繁體）",
  co: "Corsican",
  hr: "Croatian - hrvatski",
  cs: "Czech - čeština",
  da: "Danish - dansk",
  nl: "Dutch - Nederlands",
  en: "English",
  "en-AU": "English (Australia)",
  "en-CA": "English (Canada)",
  "en-IN": "English (India)",
  "en-NZ": "English (New Zealand)",
  "en-ZA": "English (South Africa)",
  "en-GB": "English (United Kingdom)",
  "en-US": "English (United States)",
  eo: "Esperanto - esperanto",
  et: "Estonian - eesti",
  fo: "Faroese - føroyskt",
  fil: "Filipino",
  fi: "Finnish - suomi",
  fr: "French - français",
  "fr-CA": "French (Canada) - français (Canada)",
  "fr-FR": "French (France) - français (France)",
  "fr-CH": "French (Switzerland) - français (Suisse)",
  gl: "Galician - galego",
  ka: "Georgian - ქართული",
  de: "German - Deutsch",
  "de-AT": "German (Austria) - Deutsch (Österreich)",
  "de-DE": "German (Germany) - Deutsch (Deutschland)",
  "de-LI": "German (Liechtenstein) - Deutsch (Liechtenstein)",
  "de-CH": "German (Switzerland) - Deutsch (Schweiz)",
  el: "Greek - Ελληνικά",
  gn: "Guarani",
  gu: "Gujarati - ગુજરાતી",
  ha: "Hausa",
  haw: "Hawaiian - ʻŌlelo Hawaiʻi",
  he: "Hebrew - עברית",
  hi: "Hindi - हिन्दी",
  hu: "Hungarian - magyar",
  is: "Icelandic - íslenska",
  id: "Indonesian - Indonesia",
  ia: "Interlingua",
  ga: "Irish - Gaeilge",
  it: "Italian - italiano",
  "it-IT": "Italian (Italy) - italiano (Italia)",
  "it-CH": "Italian (Switzerland) - italiano (Svizzera)",
  ja: "Japanese - 日本語",
  kn: "Kannada - ಕನ್ನಡ",
  kk: "Kazakh - қазақ тілі",
  km: "Khmer - ខ្មែរ",
  ko: "Korean - 한국어",
  ku: "Kurdish - Kurdî",
  ky: "Kyrgyz - кыргызча",
  lo: "Lao - ລາວ",
  la: "Latin",
  lv: "Latvian - latviešu",
  ln: "Lingala - lingála",
  lt: "Lithuanian - lietuvių",
  mk: "Macedonian - македонски",
  ms: "Malay - Bahasa Melayu",
  ml: "Malayalam - മലയാളം",
  mt: "Maltese - Malti",
  mr: "Marathi - मराठी",
  mn: "Mongolian - монгол",
  ne: "Nepali - नेपाली",
  no: "Norwegian - norsk",
  nb: "Norwegian Bokmål - norsk bokmål",
  nn: "Norwegian Nynorsk - nynorsk",
  oc: "Occitan",
  or: "Oriya - ଓଡ଼ିଆ",
  om: "Oromo - Oromoo",
  ps: "Pashto - پښتو",
  fa: "Persian - فارسی",
  pl: "Polish - polski",
  pt: "Portuguese - português",
  "pt-BR": "Portuguese (Brazil) - português (Brasil)",
  "pt-PT": "Portuguese (Portugal) - português (Portugal)",
  pa: "Punjabi - ਪੰਜਾਬੀ",
  qu: "Quechua",
  ro: "Romanian - română",
  mo: "Romanian (Moldova) - română (Moldova)",
  rm: "Romansh - rumantsch",
  ru: "Russian - русский",
  gd: "Scottish Gaelic",
  sr: "Serbian - српски",
  sh: "Serbo-Croatian - Srpskohrvatski",
  sn: "Shona - chiShona",
  sd: "Sindhi",
  si: "Sinhala - සිංහල",
  sk: "Slovak - slovenčina",
  sl: "Slovenian - slovenščina",
  so: "Somali - Soomaali",
  st: "Southern Sotho",
  es: "Spanish - español",
  "es-AR": "Spanish (Argentina) - español (Argentina)",
  "es-419": "Spanish (Latin America) - español (Latinoamérica)",
  "es-MX": "Spanish (Mexico) - español (México)",
  "es-ES": "Spanish (Spain) - español (España)",
  "es-US": "Spanish (United States) - español (Estados Unidos)",
  su: "Sundanese",
  sw: "Swahili - Kiswahili",
  sv: "Swedish - svenska",
  tg: "Tajik - тоҷикӣ",
  ta: "Tamil - தமிழ்",
  tt: "Tatar",
  te: "Telugu - తెలుగు",
  th: "Thai - ไทย",
  ti: "Tigrinya - ትግርኛ",
  to: "Tongan - lea fakatonga",
  tr: "Turkish - Türkçe",
  tk: "Turkmen",
  tw: "Twi",
  uk: "Ukrainian - українська",
  ur: "Urdu - اردو",
  ug: "Uyghur",
  uz: "Uzbek - o‘zbek",
  vi: "Vietnamese - Tiếng Việt",
  wa: "Walloon - wa",
  cy: "Welsh - Cymraeg",
  fy: "Western Frisian",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba - Èdè Yorùbá",
  zu: "Zulu - isiZulu",
};
let fl = [];

let vallist = Object.values(ll);

vallist.forEach((l) =>
  fl.push({
    id: vallist.indexOf(l),
    name: l,
  })
);
const languages_list = fl;

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (uid, authKey) => dispatch(actions.auth(uid, authKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartInterface);

// export default StartInterface
