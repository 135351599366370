import React from "react";
import { connect } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { useEffect, useState } from "react";
import { COMETCHAT_CONSTANTS } from "../consts";
import { CometChat } from "@cometchat-pro/chat";

import * as actions from "../store/action";

function LogoutButton(props) {
  // useEffect(() => {
  //   // console.log("user", props.user.uid);
  //   deleteUser()
  // }, []);

  const deleteCurrentUser = async () => {
    try {
      const response = await axios.delete(
        `https://2322758db7c35e53.api-eu.cometchat.io/v3/users/${props.user.uid}`,
        {
          headers: {
            "apiKey": COMETCHAT_CONSTANTS.REST_API_KEY,
            "Content-Type": "application/json",
          },
          data: { permanent: true },
        }
      );

      console.log(response);
    } catch (error) {
      console.error(error);
    }

  };

  const deleteConversation = () =>{
    CometChat.deleteConversation('listener1', 'user').then(
      deletedConversation => {
          console.log(deletedConversation);
      }, error => {
          console.log('error while deleting a conversation', error);
      }
  );
  }

  const handleExit = () => {
    // deleteConversation()
    props.onLogout();
  };

  return (
    <Tooltip title="Delete and exit">
      <IconButton color="red" onClick={handleExit}>
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    loading: state.loading,
    error: state.error,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton);
